import { cva } from 'class-variance-authority'
import React, {
  MouseEvent,
  PropsWithoutRef,
  RefAttributes,
  useImperativeHandle,
  useRef,
} from 'react'
import { IconProps } from '../utils/prop-types'
import Loader from './Loader'
import CaretDownIcon from './icons/CaretDown'

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'secondaryTransparent'
  | 'interactive'
  | 'danger'
  | 'icon'
type ButtonSizes = 'sm' | 'md'
type IconPositions = 'left' | 'right'
type IconType =
  | React.ComponentType<React.PropsWithChildren<IconProps>>
  | React.ElementType
  | null
interface ButtonProps {
  variant?: ButtonVariants
  size?: ButtonSizes
  fullWidth?: boolean
  icon?: IconType
  iconPosition?: IconPositions
  loading?: ConstrainBoolean
  children?: React.ReactNode
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>
  disclosure?: boolean
  ghost?: boolean
  bordered?: boolean
  selected?: boolean
  asChild?: boolean
  className?: string
}

const defaultProps = {
  variant: 'primary' as ButtonVariants,
  size: 'sm' as ButtonSizes,
  fullWidth: false,
  iconPosition: 'left' as IconPositions,
  loading: false,
  disabled: false,
  disclosure: false,
  ghost: false,
  bordered: false,
  selected: false,
  className: '',
}

type NativeAttrs = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement | HTMLDivElement | null>,
  keyof ButtonProps
>
export type ButtonPropsType = ButtonProps & typeof defaultProps & NativeAttrs

export const buttonVariants = cva(
  'group flex items-center justify-center rounded-lg text-2xs font-medium focus-visible:outline-none focus-visible:ring focus-visible:ring-focused focus-visible:ring-offset-1 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary: [
          'bg-purple-500',
          'text-white',
          'hover:bg-purple-600',
          'active:bg-purple-700',
          'disabled:bg-gray-50',
          'disabled:text-gray-500',
        ],
        secondary: [
          'bg-white',
          'border',
          'border-gray-400',
          'text-black',
          'hover:bg-gray-100',
          'hover:border-gray-600',
          'active:bg-gray-200',
          'active:border-gray-700',
          'disabled:bg-gray-50',
          'disabled:border-gray-300',
          'disabled:text-gray-500',
        ],
        secondaryTransparent: [
          'bg-transparent',
          'border',
          'border-gray-400',
          'text-black',
          'hover:bg-gray-100',
          'hover:border-gray-600',
          'active:bg-gray-200',
          'active:border-gray-700',
          'disabled:bg-gray-50',
          'disabled:border-gray-300',
          'disabled:text-gray-500',
        ],
        danger: [
          'bg-red-500',
          'text-white',
          'hover:bg-red-600',
          'active:bg-red-700',
          'disabled:bg-gray-50',
          'disabled:text-gray-500',
        ],
        interactive: [
          'text-purple-500',
          '!px-0',
          '!py-0',
          '!rounded',
          'hover:text-purple-600',
          'active:text-purple-700',
          'disabled:text-gray-500',
        ],
        icon: [
          '!rounded',
          'bg-transparent',
          'hover:bg-gray-100',
          'active:bg-gray-200',
          'disabled:!border-gray-300',
          'disabled:!bg-gray-50',
          'disabled:opacity-60',
        ],
      },
      size: {
        sm: 'px-4 py-2 w-auto',
        md: 'px-5 py-4 w-auto',
      },
      fullWidth: {
        true: 'w-full',
        false: 'w-auto',
      },
      iconPosition: {
        left: '',
        right: '',
      },
      ghost: {
        true: [
          'bg-transparent',
          'hover:bg-transparent',
          'active:bg-transparent',
          'disabled:bg-transparent',
          'disabled:!text-gray-500',
          '[&>svg]:disabled:opacity-20',
          'border-none',
          '!p-0.5',
        ],
        false: '',
      },
      bordered: {
        true: 'border border-gray-700',
        false: '',
      },
      selected: {
        true: '!bg-purple-200',
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        ghost: true,
        className: [
          '!text-purple-500',
          'hover:!text-purple-600',
          'active:!text-purple-700',
        ],
      },
      {
        variant: 'secondary',
        ghost: true,
        className: [
          '!text-gray-700',
          'hover:!text-black',
          'active:!text-gray-700',
        ],
      },
      {
        variant: 'danger',
        ghost: true,
        className: [
          '!text-red-500',
          'hover:!text-red-600',
          'active:!text-red-700',
        ],
      },
      {
        variant: 'icon',
        size: 'sm',
        className: ['!w-6', '!p-1'],
      },
      {
        variant: 'icon',
        size: 'md',
        className: ['!w-9', '!p-2'],
      },
      {
        variant: 'icon',
        bordered: true,
        selected: true,
        className: ['!border-purple-500', '!bg-transparent'],
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'sm',
      fullWidth: false,
      ghost: false,
      selected: false,
    },
  }
)

/* eslint-disable react/display-name */
const Button = React.forwardRef<
  HTMLButtonElement | HTMLDivElement | null,
  React.PropsWithChildren<ButtonPropsType>
>(
  (
    { ...btnProps },
    ref: React.Ref<HTMLButtonElement | HTMLDivElement | null>
  ) => {
    if (btnProps.variant === 'icon' && !btnProps.icon) {
      throw new Error("icon component is required when type='icon'!")
    }
    if (btnProps.variant !== 'icon' && btnProps.bordered) {
      throw new Error("Only button type='icon' can be optionally bordered")
    }
    if (btnProps.variant !== 'icon' && btnProps.selected) {
      throw new Error("Only button type='icon' can be optionally bordered")
    }
    if (btnProps.ghost && btnProps.loading) {
      throw new Error("Tertiary icons aren't supposed to trigger async actions")
    }

    const buttonRef = useRef(null)
    useImperativeHandle(ref, () => buttonRef.current)
    const {
      variant,
      size,
      fullWidth,
      icon: Icon,
      iconPosition,
      loading,
      children,
      className,
      disabled,
      ghost,
      bordered,
      disclosure,
      selected,
      asChild = false,
      onClick,
      ...props
    } = btnProps

    const isIconOnly = Icon && variant === 'icon'
    const showIcon =
      Icon && (ghost || (variant !== 'danger' && variant !== 'primary'))
    const showDisclosureCaret = !Icon && variant === 'secondary' && disclosure
    const Component = asChild ? 'div' : 'button'

    const clickHandler = (
      event: MouseEvent<HTMLButtonElement | HTMLDivElement>
    ) => {
      if (disabled) {
        return
      }
      onClick && onClick(event)
    }

    return (
      <Component
        ref={buttonRef}
        onClick={clickHandler}
        disabled={disabled || loading}
        {...props}
        className={`${buttonVariants({
          variant,
          size,
          fullWidth,
          iconPosition,
          ghost: ghost && !isIconOnly,
          bordered,
          selected,
        })} ${className}`}
      >
        {loading ? (
          <>
            <Loader className="mr-2.5" />
            {children}
          </>
        ) : isIconOnly ? (
          <Icon
            width={size === 'sm' ? 16 : 20}
            height={size === 'sm' ? 16 : 20}
          />
        ) : (
          <>
            {showIcon && (!iconPosition || iconPosition === 'left') && (
              <div className="mr-1">
                <Icon width={18} height={18} />
              </div>
            )}
            {children}
            {showIcon && iconPosition === 'right' && (
              <div className="ml-2.5">
                <Icon width={18} height={18} />
              </div>
            )}
            {showDisclosureCaret && (
              <CaretDownIcon
                className="ml-2.5 w-2 text-gray-500 group-hover:text-gray-1000"
                width={8}
                height={5}
              />
            )}
          </>
        )}
      </Component>
    )
  }
)

type ButtonComponent<T, P> = React.ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
>

type ComponentProps = Partial<typeof defaultProps> &
  Omit<ButtonProps, keyof typeof defaultProps> &
  NativeAttrs

Button.defaultProps = defaultProps

export default React.memo(Button) as ButtonComponent<
  HTMLButtonElement | HTMLDivElement | null,
  ComponentProps
>
