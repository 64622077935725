// export const getDisplayName = (WrappedComponent: Node) => {
//   return WrappedComponent.displayName || WrappedComponent.name || 'Component'
// }

export const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export const normalizeInstagramProfileUrl = (url: string) => {
  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }
  url = url.split('/?')[0]
  const suffix = '/channel'
  if (url.endsWith(suffix)) {
    url = url.split(suffix)[0]
  }
  return url
}

export const toReadableDate = (date: Date) => {
  date = new Date(date)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  return `${dayNames[date.getDay()]}, ${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`
}

export const datesAreOnSameDay = (first: Date, second: Date) => {
  first = new Date(first)
  second = new Date(second)
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}

export const datesAreOnSameMonth = (first: Date, second: Date) => {
  first = new Date(first)
  second = new Date(second)
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth()
  )
}

export function formatAmountForDisplay(amount, currency = 'usd', options = {}) {
  const numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    ...options,
  })
  return numberFormat.format(amount)
}

export function formatAmountToParts(amount, currency = 'usd', options = {}) {
  const numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'code',
    ...options,
  })

  return numberFormat.formatToParts(amount)
}

export function formatAmountForStripe(amount, currency = 'usd') {
  const numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency
  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }
  return zeroDecimalCurrency ? amount : Math.round(amount * 100)
}

export function getFontFamilyDescription({ fontFamily, fontFamilyEnum }) {
  return fontFamilyEnum?.find((item) => item.name === fontFamily)?.description
}

export function copyText(text: string): void {
  // const textarea = document.createElement('textarea')

  // textarea.value = text
  // document && document.body && document.body.appendChild(textarea)
  // textarea.select()
  // document.execCommand('copy')
  // document.body && document.body.removeChild(textarea)

  try {
    navigator.clipboard.writeText(text)
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

export const isGraphQLForbiddenError = (error) => {
  return (
    error?.graphQLErrors &&
    error?.graphQLErrors.length &&
    error?.graphQLErrors[0].extensions?.code === 'FORBIDDEN'
  )
}
