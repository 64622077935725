import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import {
  getMainDefinition,
  removeDirectivesFromDocument,
} from '@apollo/client/utilities'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { getSession, signOut } from 'next-auth/react'
import { useMemo } from 'react'

import generatedIntrospection from '@snipfeed/graphql/introspectionResult'

import { recaptchaMiddleware } from './recaptcha'

import { isJwtExpired } from '@/utils/next-auth/helpers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageProps = Record<string, any>

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined
let currentUserBearerAccessToken: string | undefined
let currentPlanolyUserBearerAccessToken: string | undefined

export function createApolloClient({
  planolyAccessToken: initialPlanolyToken,
  accessToken: initialAccessToken,
  accessTokenType: initialAccessTokenType,
}: {
  accessToken?: string
  planolyAccessToken?: string
  accessTokenType?: 'Bearer' | 'ApiKey'
} = {}) {
  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Creator: {
        fields: {
          revenue: {
            merge(existing, incoming) {
              // Better, but not quite correct.
              return { ...existing, ...incoming }
            },
          },
        },
      },
    },
  })

  // const httpLink = new HttpLink({
  //   uri: process.env.NEXT_PUBLIC_API_URL, // Server URL (must be absolute)
  //   credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  // })

  const snipfeedAuthMiddleware = async (previousContext) => {
    try {
      let authorization: string | undefined
      if (initialAccessToken && initialAccessTokenType) {
        authorization = `${initialAccessTokenType} ${initialAccessToken}`
      } else if (!initialAccessToken && !initialAccessTokenType) {
        if (
          !currentUserBearerAccessToken ||
          (currentUserBearerAccessToken &&
            isJwtExpired(currentUserBearerAccessToken) &&
            typeof window !== 'undefined')
        ) {
          const session = await getSession()

          const { snipfeedAccessToken } = session ?? previousContext ?? {}
          currentUserBearerAccessToken = snipfeedAccessToken
        }
        authorization =
          currentUserBearerAccessToken &&
          `Bearer ${currentUserBearerAccessToken}`
      }

      return {
        ...previousContext,
        headers: {
          ...(previousContext.headers ?? {}),
          ...{
            name: 'user-platform',
            version: '2.0',
          },
          authorization,
        },
      }
    } catch (e) {
      console.error(e)
      return previousContext
    }
  }

  const planolyAuthMiddleware = async (previousContext) => {
    let authorization: string | undefined

    try {
      if (initialPlanolyToken) {
        authorization = `Bearer ${initialPlanolyToken}`
      } else {
        if (!currentPlanolyUserBearerAccessToken) {
          const session = await getSession()
          const { planolyToken } = session ?? previousContext ?? {}
          currentPlanolyUserBearerAccessToken = planolyToken
        }

        authorization =
          currentPlanolyUserBearerAccessToken &&
          `Bearer ${currentPlanolyUserBearerAccessToken}`

        return {
          ...previousContext,
          headers: {
            ...(previousContext.headers ?? {}),
            authorization,
          },
        }
      }
    } catch (e) {
      console.error(e)
      return previousContext
    }
  }

  type DirectiveType = 'planoly' | 'snipfeed'

  const linkByDirective: Record<DirectiveType, ApolloLink> = {
    planoly: new HttpLink({
      uri: process.env.NEXT_PUBLIC_PLANOLY_API_URL,
    }),
    snipfeed: new HttpLink({
      uri: process.env.NEXT_PUBLIC_API_URL,
      credentials: 'same-origin',
    }),
  }

  function findDirective(query): DirectiveType {
    const definition = getMainDefinition(query)

    const foundDirective =
      'operation' in definition &&
      definition.directives?.find((item) => item.name.value === 'planoly')

    const directive: DirectiveType = foundDirective
      ? (foundDirective.name.value as 'planoly')
      : 'snipfeed'

    return directive
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const link = new ApolloLink((operation, forward) => {
    const directive = findDirective(operation.query)

    // remove directive from document to not let sent to the API.
    const query = removeDirectivesFromDocument(
      [{ name: 'planoly', remove: true }],
      operation.query
    )

    operation.query = query

    const response = linkByDirective[directive].request(operation, forward)

    if (directive === 'planoly') {
      operation.setContext((context) => {
        context.cache.policies.addTypePolicies({
          User: {
            keyFields: ({ __typename }) => {
              return `Planoly:${__typename}`
            },
          },
        })
      })
    }

    return response
  })

  const authMiddleware = setContext(async (operation, previousContext) => {
    const directive = findDirective(operation.query)

    if (directive === 'planoly') {
      return await planolyAuthMiddleware(previousContext)
    } else {
      return await snipfeedAuthMiddleware(previousContext)
    }
  })

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err?.extensions?.code) {
          case 'UNAUTHENTICATED':
            // error code is set to UNAUTHENTICATED
            // when AuthenticationError thrown in resolver
            signOut()
          // retry the request, returning the new observable
        }
      }
    }
    if (networkError) {
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // @apollo/client/link/retry
    }
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      onErrorLink,
      recaptchaMiddleware,
      authMiddleware,
      link,
    ]),
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
  })
}

export function initializeApollo(initialState?: NormalizedCacheObject) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient
  }

  return _apolloClient
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PageProps
) {
  if (pageProps) {
    pageProps[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return { props: pageProps }
}

export function useApollo(pageProps: PageProps) {
  let state: NormalizedCacheObject | undefined = undefined
  if (pageProps) {
    state = pageProps[APOLLO_STATE_PROP_NAME]
  }
  const store = useMemo(() => initializeApollo(state), [state])

  return store
}

export async function resetApolloSession() {
  currentUserBearerAccessToken = undefined
  currentPlanolyUserBearerAccessToken = undefined
  await apolloClient?.clearStore()
}
