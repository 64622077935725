import { DefaultSeo } from 'next-seo'
import NextHead from 'next/head'

import config from '@/config/seo.json'

export default function SEO() {
  return (
    <>
      <DefaultSeo {...config} />
      <NextHead>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="theme-color" content="#ffffff" />
      </NextHead>
    </>
  )
}
