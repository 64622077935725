import * as DialogPrimitive from '@radix-ui/react-dialog'
import { cva } from 'class-variance-authority'
import React from 'react'
import { IconProps } from '../utils/prop-types'
import Button, { ButtonVariants } from './Button'
import CloseIcon from './icons/CloseIcon'
import { cn } from '../utils/className'

type IconType = React.ComponentType<React.PropsWithChildren<IconProps>> | null
type ModalTitleType = {
  text: string
  icon?: IconType
}
type ModalActionsType = {
  primary: {
    text: string
    onClick: () => void
    variant?: ButtonVariants
    disabled?: boolean
    loading?: boolean
  }
  secondary?: {
    text: string
    onClick: () => void
    disabled?: boolean
    loading?: boolean
  }
}

export interface ModalProps {
  children: React.ReactNode
  isOpen?: boolean
  defaultOpen?: boolean
  onOpenChange?: (open: boolean) => void
  title?: ModalTitleType
  description?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  actions?: ModalActionsType
  className?: string
}

export const modalContentVariant = cva(
  [
    'fixed z-30 p-6',
    'md:top-[50%] md:left-[50%] md:-translate-x-[50%] md:-translate-y-[50%] md:bottom-[unset]',
    'bottom-0 w-full rounded-tl-3xl	rounded-tr-3xl',
    'bg-white shadow-xl md:rounded-2xl border-none',
    'focus:outline-none focus-visible:ring focus-visible:ring-ring',
  ],
  {
    variants: {
      size: {
        sm: 'md:w-[400px]',
        md: 'w-full max-w-[600px]',
        lg: 'w-full max-w-[800px]',
        xl: 'w-full max-w-[960px]',
      },
      isOpen: {
        true: 'animate-modal-show',
        false: 'animate-modal-exit',
      },
    },
    defaultVariants: {
      size: 'lg',
      isOpen: false,
    },
  }
)

const Modal = ({
  title: { text: titleText, icon: Icon } = { text: '' },
  description,
  actions,
  isOpen,
  size = 'lg',
  onOpenChange,
  children,
  defaultOpen,
  className,
}: ModalProps) => {
  return (
    <DialogPrimitive.Root
      open={isOpen}
      onOpenChange={onOpenChange}
      defaultOpen={defaultOpen}
      modal
    >
      <DialogPrimitive.Portal forceMount>
        {isOpen && (
          <>
            <DialogPrimitive.Overlay
              forceMount
              className="fixed inset-0 z-20 bg-black/50"
            />
            <DialogPrimitive.Content
              forceMount
              className={cn(modalContentVariant({ isOpen, size }), className)}
            >
              {titleText && (
                <DialogPrimitive.Title className="text-base font-semibold text-black flex items-center font-regular">
                  {Icon && (
                    <div className="mr-4">
                      <Icon width={16} height={16} />
                    </div>
                  )}
                  {titleText}
                </DialogPrimitive.Title>
              )}
              <div className="mt-6">{children}</div>
              {description && (
                <DialogPrimitive.Description className="mt-4 text-2xs font-normal text-black">
                  {description}
                </DialogPrimitive.Description>
              )}

              {actions && (
                <div className="mt-6 flex justify-end gap-2">
                  {actions.secondary && (
                    <DialogPrimitive.Close asChild>
                      <Button
                        variant="secondary"
                        className="w-full md:w-auto"
                        onClick={actions.secondary.onClick}
                        disabled={actions.secondary.disabled}
                        loading={actions.secondary.loading}
                      >
                        {actions.secondary.text}
                      </Button>
                    </DialogPrimitive.Close>
                  )}

                  {actions.primary && (
                    <Button
                      onClick={actions.primary.onClick}
                      className="w-full md:w-auto"
                      variant={actions.primary.variant}
                      disabled={actions.primary.disabled}
                      loading={actions.primary.loading}
                      data-testid="modal-primary-action"
                    >
                      {actions.primary.text}
                    </Button>
                  )}
                </div>
              )}

              <DialogPrimitive.Close className="absolute top-4 right-4 focus:outline-none focus-visible:ring focus-visible:ring-ring">
                <CloseIcon className="h-8 w-8 text-gray-500 hover:text-gray-700" />
              </DialogPrimitive.Close>
            </DialogPrimitive.Content>
          </>
        )}
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}

export default Modal
