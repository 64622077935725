import { useQuery } from '@apollo/client'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'

import { Creator } from '@snipfeed/graphql/types'

import GET_PROFILE from '@/graphql/queries/profile.gql'

type OnboardingStepName = 'your-info' | 'select-plan'
type OnboardingStep = {
  name: string
  pathname: string
  isValid: (profile: Creator) => boolean
  previous?: OnboardingStepName
  next?: OnboardingStepName
}

export const firstStep: OnboardingStepName = 'your-info'
const finalStep: OnboardingStepName = 'select-plan'

export const ONBOARDING_STEPS: { [key in OnboardingStepName]: OnboardingStep } =
  {
    [firstStep]: {
      name: 'your-info',
      pathname: '/signup/your-information',
      isValid: (profile) =>
        !!profile.name &&
        !!profile.onboardingData?.categoryChoiceStep?.choices &&
        !!profile.onboardingData?.categoryChoiceStep?.choices.length,
      next: 'select-plan',
    },
    [finalStep]: {
      name: 'select-plan',
      pathname: '/upgrade/select-plan',
      isValid: (profile) => !!profile.onboardingData?.planChoiceStep?.choice,
      previous: 'your-info',
    },
  }

const getCurrentStep = (profile: Creator): OnboardingStep => {
  let currentStep = ONBOARDING_STEPS[firstStep]

  while (currentStep.isValid(profile) && !!currentStep.next) {
    currentStep = ONBOARDING_STEPS[currentStep.next]
  }

  return currentStep
}

type OnboardingGuardProps = {
  children: ReactNode
}

let completedOnboardingCache = false

export default function OnboardingGuard({
  children,
}: OnboardingGuardProps): JSX.Element {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const { status: sessionStatus, data: session } = useSession()
  const router = useRouter()
  const { session_id: sessionId } = router.query

  const { data: { profile } = {}, loading: loadingProfile } = useQuery<{
    profile: Creator
  }>(GET_PROFILE, {
    skip: sessionStatus !== 'authenticated',
    fetchPolicy: 'cache-first',
  })

  const isFromPlanoly = profile?.isFromPlanoly || Boolean(session?.planolyToken)

  useEffect(() => {
    if (loadingProfile || sessionStatus === 'loading' || !profile) {
      return
    }

    // Bypass authorization if user is redirected back from stripe checkout
    // to avoid final onboarding step race condition
    if (
      (router.pathname === '/get-started' && sessionId) ||
      completedOnboardingCache
    ) {
      setIsAuthorized(true)
      completedOnboardingCache = true
      return
    }

    if (isFromPlanoly) {
      const isAuthorizedPlanolyAccount = !profile.isEligibleForPlanolyTrial

      if (router.pathname.includes('/welcome/planoly')) {
        if (isAuthorizedPlanolyAccount) {
          router.push('/')
          return
        }
        setIsAuthorized(true)
        return
      }

      // Wasn't a Snipfeed account and still hasn't accepted trial
      if (!isAuthorizedPlanolyAccount) {
        router.push('/welcome/planoly/trial')
        return
      }

      setIsAuthorized(true)
      return
    }

    if (ONBOARDING_STEPS[finalStep].isValid(profile) || profile.isUpgraded) {
      setIsAuthorized(true)
      return
    }

    const currentStep = getCurrentStep(profile)
    if (router.pathname !== currentStep.pathname) {
      router.push(currentStep.pathname)
      return
    }
    setIsAuthorized(true)
  }, [
    loadingProfile,
    sessionStatus,
    profile,
    router.pathname,
    router,
    isFromPlanoly,
    sessionId,
  ])

  if (!isAuthorized) {
    return <></>
  }
  return <>{children}</>
}
