import { offset } from '@floating-ui/dom'
import Shepherd from 'shepherd.js'

import { ExclusiveContentStatusEnum } from '@snipfeed/graphql/types'
import { BadgeProps } from '@snipfeed/tint2/components/Badge'

export const MONETIZABLE_STATUS_TO_BADGE: {
  [key in ExclusiveContentStatusEnum]: BadgeProps['type']
} = {
  DRAFT: 'warning',
  PUBLISHED: 'success',
  UNPUBLISHED: 'neutral',
} as const

export const PLANOLY_AUTH_TOKEN_COOKIE_KEY = 'PLANOLY_AUTH_TOKEN'
export const IS_NEW_ACCOUNT_SESSION_KEY = 'IS_NEW_ACCOUNT'

const allowTouchmove = (event: TouchEvent) => {
  event.stopPropagation()
}
export const linkTour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: true,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 12, crossAxis: 0 })],
    },
    when: {
      show() {
        const target = this.getTarget()
        if (target) {
          target.addEventListener('touchmove', allowTouchmove)
        }

        // Disallow overriding default input focus behavior
        const element = this.getElement()
        if (element) {
          element.focus = () => {
            /* Do nothing */
          }
        }
      },
      hide() {
        const target = this.getTarget()
        if (target) {
          target.removeEventListener('touchmove', allowTouchmove)
        }
      },
    },
  },
})

export const primaryTourButtonClasses =
  'group flex items-center justify-center rounded-lg text-2xs font-medium focus-visible:outline-none focus-visible:ring focus-visible:ring-focused focus-visible:ring-offset-1 disabled:cursor-not-allowed bg-purple-500 text-white !hover:bg-purple-600 active:bg-purple-700 disabled:bg-gray-50 disabled:text-gray-500 px-4 py-2 w-auto w-auto'
export const secondaryTourButtonClasses =
  'group flex items-center justify-center rounded-lg text-2xs font-medium focus-visible:outline-none focus-visible:ring focus-visible:ring-focused focus-visible:ring-offset-1 disabled:cursor-not-allowed bg-white border border-gray-400 text-black hover:bg-gray-100 hover:border-gray-600 active:bg-gray-200 active:border-gray-700 disabled:bg-gray-50 disabled:border-gray-300 disabled:text-gray-500 px-4 py-2 w-auto w-auto'
