interface CloseIconProps {
  className?: string
  width?: number
  height?: number
}

export default function CloseIcon({
  className,
  width = 20,
  height = 20,
}: CloseIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.1667 5.83337L5.83334 14.1667M5.83334 5.83337L14.1667 14.1667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
