import { createApolloClient } from '@/lib/apolloClient'

export const createRootApolloClient = () => {
  if (!process.env.BACKEND_API_KEY) {
    throw new Error(
      'You need to configure BACKEND_API_KEY environment variable first'
    )
  }

  return createApolloClient({
    accessToken: process.env.BACKEND_API_KEY,
    accessTokenType: 'ApiKey',
  })
}
