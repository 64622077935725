import { useCallback, useEffect, useState } from 'react'

const useBuildId = () => {
  const [buildId, setBuildId] = useState<string | null>(
    process.env.BUILD_ID ?? null
  )
  const refreshBuildId = useCallback(async () => {
    try {
      const response = await fetch('/api/build-id')
      const data = await response.json()
      setBuildId(data.buildId)
    } catch (e) {
      console.error(e)
    }
  }, [])
  useEffect(() => {
    window.addEventListener('focus', refreshBuildId)
    return () => {
      window.removeEventListener('focus', refreshBuildId)
    }
  }, [refreshBuildId])

  return buildId
}

export default useBuildId
