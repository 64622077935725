import { forwardRef } from 'react'
import * as ToastPrimitives from '@radix-ui/react-toast'
import { cva } from 'class-variance-authority'

export interface ToastProps
  extends React.ComponentProps<typeof ToastPrimitives.Root> {
  title: string
  action?: ToastActionElement
  variant?: 'default' | 'error'
}

const toastRootVariants = cva(
  'absolute bottom-0 left-1/2  -translate-x-1/2  bg-black text-white p-4 rounded-lg h-10 w-11/12 md:w-auto md:h-14 flex items-center justify-between data-[state=open]:animate-slide-up data-[state=closed]:animate-slide-down',
  {
    variants: {
      variant: {
        default: 'bg-black',
        error: 'bg-red-500',
      },
    },
  }
)

export default function Toast({
  title,
  action,
  variant = 'default',
  ...props
}: ToastProps) {
  return (
    <ToastPrimitives.Root className={toastRootVariants({ variant })} {...props}>
      <ToastPrimitives.Title>{title}</ToastPrimitives.Title>
      {action}
      <ToastPrimitives.Close className="ml-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M17 3L3 17M3 3l14 14"
          ></path>
        </svg>
      </ToastPrimitives.Close>
    </ToastPrimitives.Root>
  )
}

export const ToastAction = forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>((props, ref) => (
  <ToastPrimitives.Action ref={ref} className="ml-8" {...props} />
))
ToastAction.displayName = ToastPrimitives.Action.displayName

export const ToastViewport = forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>((props, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className="fixed z-50 flex max-h-screen w-full p-4 bottom-5 left-0 m-auto"
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

export const ToastProvider = ToastPrimitives.Provider
export type ToastActionElement = React.ReactElement<typeof ToastAction>
