import { useRef, useEffect } from 'react'

const usePrevious = <T>(value: T): T => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ref.current!
}

export default usePrevious
