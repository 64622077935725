import { signOut } from 'next-auth/react'

import { useApollo } from '@/lib/apolloClient'

export default function useLogout() {
  const apolloClient = useApollo({})

  const onLogout = async (callbackUrl?: string) => {
    const _hsp = (window._hsp = window._hsp || [])

    _hsp.push(['revokeCookieConsent'])

    await apolloClient.clearStore()
    await signOut({ redirect: true, callbackUrl: callbackUrl ?? '/login' })
  }

  return { onLogout }
}
