import useToast from '../hooks/useToast'
import Toast, { ToastProvider, ToastViewport } from './Toast'

export default function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, ...props }, index) {
        const reverseIndex = index
        const calc = `100% - 56px - ${18 * reverseIndex}px`
        let translate = `translate3d(-50%, calc(${calc}), -${reverseIndex}px) scale(${
          1 - 0.05 * reverseIndex
        })`

        if (reverseIndex >= 4) {
          translate = `translate3d(-50%, -56px, -${reverseIndex}px) scale(.7)`
        }

        return (
          <Toast
            key={id}
            {...props}
            style={{
              transform: translate,
            }}
          />
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
