import Router from 'next/router'
import nProgress from 'nprogress'
import { useCallback, useEffect, useState } from 'react'

let timer: ReturnType<typeof setTimeout>

export default function useProgress() {
  const [isLoading, setIsLoading] = useState<boolean>()

  const routeChangeStart = useCallback(() => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    clearTimeout(timer)
    timer = setTimeout(function () {
      nProgress.start()
    }, 250)
  }, [isLoading])

  const routeChangeEnd = useCallback(() => {
    setIsLoading(false)

    clearTimeout(timer)
    nProgress.done()
  }, [])

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeEnd)
  }, [routeChangeStart, routeChangeEnd])

  return () => {
    Router.events.off('routeChangeStart', routeChangeStart)
    Router.events.off('routeChangeComplete', routeChangeEnd)
    Router.events.off('routeChangeError', routeChangeEnd)
  }
}
