interface CaretDownIconProps {
  color?: string
  className?: string
  width?: number
  height?: number
}

export default function CaretDownIcon({
  color = '#96928C',
  className,
  width = 8,
  height = 5,
}: CaretDownIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 5"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.09832 3.98009e-07L0.901685 -1.43717e-07C0.15069 -2.09371e-07 -0.269991 0.75351 0.193991 1.2676L3.29231 4.70055C3.65265 5.09982 4.34735 5.09982 4.70769 4.70055L7.80601 1.2676C8.26999 0.753511 7.84931 4.63664e-07 7.09832 3.98009e-07Z"
        fill="currentColor"
      />
    </svg>
  )
}
