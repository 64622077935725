export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const onboardingPlanUpgradeSelected = () => {
  window.dataLayer.push({
    event: 'onboardingPlanUpgradeSelected',
  })
}

export const onboardingCustomDomainSelected = () => {
  window.dataLayer.push({
    event: 'onboardingCustomDomainSelected',
  })
}

export const onboardingMonetizableServicesSelected = (
  monetizableServices: string[]
) => {
  window.dataLayer.push({
    event: 'onboardingMonetizableServicesSelected',
    onboardingMonetizableServicesSelected: monetizableServices,
  })
}

export const onboardingLinkTemplateSelected = (templateId) => {
  window.dataLayer.push({
    event: 'onboardingLinkTemplateSelected',
    onboardingLinkTemplateSelected: templateId,
  })
}

export const onboardingCategoriesSubmitted = (categories: string[]) => {
  window.dataLayer.push({
    event: 'onboardingCategoriesSubmitted',
    onboardingCategoriesSubmitted: categories,
  })
}

export const onboardingSocialLinksSubmitted = (links: {
  bioLink?: string
  socialLinks?: string[]
}) => {
  window.dataLayer.push({
    event: 'onboardingSocialLinksSubmitted',
    onboardingSocialLinksSubmitted: links,
  })
}

export const pageview = () => {
  window.dataLayer.push({
    event: 'pageview',
    url: window.location.href,
    search: window.location.search,
    referrer: window.document.referrer,
    path: window.location.pathname,
  })
}

export const planUpgrade = ({
  userId,
  email,
  name,
  username,
  referredBy,
  createdAt,
  isPro,
  isStarter,
}: {
  userId: string
  email: string | null
  name: string | null
  username: string
  referredBy?: string | null
  createdAt: string
  isPro?: boolean
  isStarter?: boolean
}) => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'planUpgrade',
      user: {
        id: userId,
        userType: 'Creator',
        email,
        name,
        username,
        referredBy,
        createdAt,
        isPro,
        isStarter,
      },
    })
  }
}

export const identify = ({
  userId,
  databaseId,
  email,
  name,
  username,
  referredBy,
  createdAt,
  isPro,
  phone,
  categories,
  plan,
  stripePriceId,
  isOnTrial,
  planolyId,
  socialSetId,
}: {
  userId: string
  databaseId: string
  email?: string | null
  name?: string | null
  username: string
  referredBy?: string | null
  createdAt: string
  isPro: boolean
  phone?: string | null
  categories?: string[] | null
  plan?: string | null
  isOnTrial?: boolean
  planolyId?: number | null
  socialSetId?: number | null
  stripePriceId?: string | null
}) => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'identify',
      user: {
        id: userId,
        databaseId,
        userType: 'Creator',
        email,
        name,
        username,
        referredBy,
        createdAt,
        isPro,
        phone,
        categories,
        plan,
        isOnTrial,
        stripePriceId,
        planolyId,
        socialSetId,
      },
    })
  }
}

export const signup = ({
  userId,
  createdAt,
}: {
  userId: string
  createdAt: string
}) => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'creatorSignup',
      user: {
        id: userId,
        userType: 'Creator',
        createdAt,
      },
    })
  }
}

export const trialConversion = () => {
  if (typeof window !== 'undefined') {
    window.dataLayer.push({
      event: 'trialConversion',
    })
  }
}
