import { setContext } from '@apollo/client/link/context'

export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY

export const getRecaptchaToken = async (action: string) =>
  new Promise((resolve, reject) => {
    try {
      // @ts-expect-error global definition doesn't work
      grecaptcha?.ready(function () {
        // @ts-expect-error definition doesn't work
        grecaptcha
          ?.execute(RECAPTCHA_SITE_KEY, { action })
          .then(resolve)
          .catch(reject)
      })
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })

export const recaptchaMiddleware = setContext(
  async (operation, previousContext) => {
    try {
      if (!previousContext.useRecaptcha) {
        return previousContext
      }
      const recaptchToken = await getRecaptchaToken(
        previousContext.recaptchaAction || 'default'
      )
      return {
        ...previousContext,
        headers: {
          ...(previousContext.headers ?? {}),
          'x-recaptcha-token': recaptchToken,
        },
      }
    } catch (e) {
      console.error(e)
      return previousContext
    }
  }
)
